document.addEventListener("readystatechange", () => {
  if (document.readyState === 'interactive') {
    getTheme();
    pagePreloader();
  }
});
document.addEventListener("DOMContentLoaded", () => {
  newIconsItemsCreate();
  changeIconsItem();
  createPannel();
  deleteDot();
});
function pagePreloader() {
  setTimeout(() => {
    let mainContent = document.querySelector('.gc-main-content');
    let preloader = document.querySelector('.loader__wrapper');
    mainContent.style.opacity = 1;
    preloader.remove();
  }, 2500);
}
function getTheme() {
  let savedTheme = localStorage.getItem("theme");
  if (savedTheme === "dark") {
    document.querySelector(".gc-user-logined").classList.add("dark-theme");
  }
}
function deleteDot() {
  let lessonList = document.querySelectorAll('.stream-table tbody tr td a > div > b');
  if (lessonList) {
    lessonList.forEach(item => {
      item.textContent = item.textContent.replace('.', ' ');
    });
  }
}
function newIconsItemsCreate() {
  let getcourseNativeMenu = document.querySelector(".gc-account-leftbar");
  let menu = `<div class="custom__icons" aria-hidden="true">
    <span class="menu-item-notifications_button_small">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33497 4.72727V5.25342C6.64516 6.35644 4.76592 9.97935 4.83412 13.1192L4.83409 14.8631C3.45713 16.6333 3.53815 19.2727 6.9735 19.2727H9.33497C9.33497 19.996 9.61684 20.6897 10.1186 21.2012C10.6203 21.7127 11.3008 22 12.0104 22C12.72 22 13.4005 21.7127 13.9022 21.2012C14.404 20.6897 14.6858 19.996 14.6858 19.2727H17.0538C20.4826 19.2727 20.5323 16.6278 19.1555 14.8576L19.1938 13.1216C19.2631 9.97811 17.3803 6.35194 14.6858 5.25049V4.72727C14.6858 4.00396 14.404 3.31026 13.9022 2.7988C13.4005 2.28734 12.72 2 12.0104 2C11.3008 2 10.6203 2.28734 10.1186 2.7988C9.61684 3.31026 9.33497 4.00395 9.33497 4.72727ZM12.9022 4.72727C12.9022 4.74573 12.9017 4.76414 12.9006 4.78246C12.6101 4.74603 12.3142 4.72727 12.014 4.72727C11.7113 4.72727 11.413 4.74634 11.1203 4.78335C11.1192 4.76474 11.1186 4.74603 11.1186 4.72727C11.1186 4.48617 11.2126 4.25494 11.3798 4.08445C11.547 3.91396 11.7739 3.81818 12.0104 3.81818C12.2469 3.81818 12.4738 3.91396 12.641 4.08445C12.8083 4.25494 12.9022 4.48617 12.9022 4.72727ZM11.1186 19.2727C11.1186 19.5138 11.2126 19.7451 11.3798 19.9156C11.547 20.086 11.7739 20.1818 12.0104 20.1818C12.2469 20.1818 12.4738 20.086 12.641 19.9156C12.8083 19.7451 12.9022 19.5138 12.9022 19.2727H11.1186ZM17.0538 17.4545C17.8157 17.4545 18.2267 16.5435 17.7309 15.9538C17.49 15.6673 17.3616 15.3028 17.3699 14.9286L17.4106 13.0808C17.4787 9.99416 15.0427 6.54545 12.014 6.54545C8.98598 6.54545 6.55028 9.99301 6.61731 13.0789L6.65748 14.9289C6.66561 15.303 6.53726 15.6674 6.29639 15.9538C5.80054 16.5435 6.21158 17.4545 6.9735 17.4545H17.0538Z" fill="#000000"/>
      </svg>
    </span>
    <span class="menu-item-cms">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H10C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7H6Z" fill="#000000"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V16C22 18.2091 20.2091 20 18 20H6C3.79086 20 2 18.2091 2 16V8ZM18 6C19.1046 6 20 6.89543 20 8V10H4V8C4 6.89543 4.89543 6 6 6H18ZM20 12H4V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V12Z" fill="#000000"/>
      </svg>
    </span>
    <span class="menu-item-teach">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H13C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7H7Z" fill="#000000"/>
        <path d="M6 12C6 11.4477 6.44772 11 7 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H7C6.44772 13 6 12.5523 6 12Z" fill="#000000"/>
        <path d="M7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15H7Z" fill="#000000"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.625 3H7.03369C7.71014 2.37135 8.6473 2 9.62497 2H18.375C20.263 2 22 3.38491 22 5.33333V14.6667C22 16.6151 20.263 18 18.375 18H17.94C17.631 19.697 16.1637 21 14.375 21H5.625C3.60818 21 2 19.3435 2 17.3333V6.66667C2 4.65653 3.60818 3 5.625 3ZM18.375 16H18V6.66667C18 5.62093 17.5648 4.67089 16.8632 4H18.375C19.3864 4 20 4.70443 20 5.33333V14.6667C20 15.2956 19.3864 16 18.375 16ZM5.625 5C4.74233 5 4 5.73129 4 6.66667V17.3333C4 18.2687 4.74233 19 5.625 19H14.375C15.2577 19 16 18.2687 16 17.3333V6.66667C16 5.73129 15.2577 5 14.375 5H5.625Z" fill="#000000"/>
      </svg>
    </span>
    <span class="menu-item-user">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z" stroke="#000000" stroke-width="2"/>
        <path d="M5 19.5C5 15.9101 7.91015 13 11.5 13H12.5C16.0899 13 19 15.9101 19 19.5V20C19 20.5523 18.5523 21 18 21H6C5.44772 21 5 20.5523 5 20V19.5Z" stroke="#000000" stroke-width="2"/>
      </svg>
    </span>
    <span class="menu-item-tasks">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.8321 9.5547C18.1384 9.09517 18.0142 8.4743 17.5547 8.16795C17.0952 7.8616 16.4743 7.98577 16.168 8.4453L13.3925 12.6085L10.0529 10.3542C9.421 9.92768 8.55941 10.1339 8.18917 10.8004L6.12584 14.5144C5.85763 14.9971 6.03157 15.6059 6.51436 15.8742C6.99714 16.1424 7.60594 15.9684 7.87416 15.4856L9.56672 12.439L12.8571 14.66C13.4546 15.0634 14.2662 14.9035 14.6661 14.3036L17.8321 9.5547Z" fill="#000000"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM4 7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V7Z" fill="#000000"/>
      </svg>
    </span>
    <span class="menu-item-notifications">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.21917 10.3753C6.56418 9.94404 7.19347 9.87412 7.62473 10.2191L12 13.7194L16.3753 10.2191C16.8066 9.87412 17.4359 9.94404 17.7809 10.3753C18.1259 10.8066 18.056 11.4359 17.6247 11.7809L13.2494 15.2811C12.519 15.8655 11.4811 15.8655 10.7506 15.2811L6.37534 11.7809C5.94408 11.4359 5.87416 10.8066 6.21917 10.3753Z" fill="#000000"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.926 3.41714C12.7266 2.75817 11.2734 2.75817 10.074 3.41714L4.07399 6.71343C2.79486 7.41616 2 8.75977 2 10.2192V17C2 19.2091 3.79086 21 6 21H18C20.2091 21 22 19.2091 22 17V10.2192C22 8.75976 21.2051 7.41616 19.926 6.71343L13.926 3.41714ZM11.037 5.17002C11.6367 4.84054 12.3633 4.84054 12.963 5.17002L18.963 8.46632C19.6026 8.81768 20 9.48949 20 10.2192V17C20 18.1046 19.1046 19 18 19H6C4.89543 19 4 18.1046 4 17V10.2192C4 9.48949 4.39743 8.81768 5.03699 8.46632L11.037 5.17002Z" fill="#000000"/>
      </svg>
    </span>
    <span class="menu-item-sales">
      <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.85795 8.84661C4.93811 7.80461 5.80699 7 6.85206 7H17.1479C18.193 7 19.0619 7.80461 19.142 8.84661L19.6687 15.6932C19.8474 18.0164 18.0105 20 15.6805 20H8.31951C5.98947 20 4.15259 18.0164 4.33129 15.6932L4.85795 8.84661Z" stroke="#000000" stroke-width="2"/>
        <path d="M15 11V6C15 4.34315 13.6569 3 12 3V3C10.3431 3 9 4.34315 9 6V11" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </span>
    <li class="menu-item menu-item-theme">
      <a href="javascript:void(0)" title="Изменить тему">
        <span class="menu-item-theme custom-icon light-icon">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0972 2.53039C12.2913 2.8649 12.2752 3.28136 12.0557 3.5998C11.3898 4.56594 11 5.73595 11 7.00002C11 10.3137 13.6863 13 17 13C18.2641 13 19.4341 12.6102 20.4002 11.9443C20.7187 11.7249 21.1351 11.7087 21.4696 11.9028C21.8041 12.0969 21.9967 12.4665 21.9642 12.8519C21.5313 17.9765 17.236 22 12 22C6.47715 22 2 17.5229 2 12C2 6.76398 6.02351 2.46874 11.1481 2.03585C11.5335 2.0033 11.9031 2.19588 12.0972 2.53039ZM9.42424 4.42352C6.26994 5.49553 4 8.48306 4 12C4 16.4183 7.58172 20 12 20C15.517 20 18.5045 17.7301 19.5765 14.5758C18.7676 14.8508 17.9008 15 17 15C12.5817 15 9 11.4183 9 7.00002C9 6.09922 9.1492 5.2324 9.42424 4.42352Z" fill="#000000"/>
          </svg>
        </span>
        <span class="menu-item-theme custom-icon dark-icon hidden">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="40px" height="40px">
            <path d="M12 2V4M12 20V22M4 12H2M6.31412 6.31412L4.8999 4.8999M17.6859 6.31412L19.1001 4.8999M6.31412 17.69L4.8999 19.1042M17.6859 17.69L19.1001 19.1042M22 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </a>
    </li>
  </div>
  `;
  if (getcourseNativeMenu) {
    getcourseNativeMenu.insertAdjacentHTML("beforeEnd", menu);
    setTimeout(() => {
      document.querySelector(".custom__icons").style.display = "none";
    }, 300);
  }
}
function changeIconsItem() {
  let currentIconList = document.querySelectorAll("ul li.menu-item");
  let customIcon;
  let currentLink;
  function changeIcons(currentLink, customIcon) {
    customIcon.classList.add("custom-icon");
    return currentLink.append(customIcon);
  }
  if (currentIconList) {
    let currentTheme = document.querySelector(".gc-user-logined").classList.contains("dark-theme") ? "dark" : "light";
    let parentUl = currentIconList[0].parentNode;
    let themeIcon = document.querySelector("li.menu-item-theme");
    function toggleThemeIcon() {
      themeIcon.querySelectorAll("span").forEach(icon => { icon.classList.toggle("hidden") })
    }
    if (currentTheme === "dark") { toggleThemeIcon() }
    parentUl.appendChild(themeIcon);
    themeIcon.addEventListener("click", () => {
      document.querySelector(".gc-user-logined").classList.toggle("dark-theme");
      currentTheme = document.querySelector(".gc-user-logined").classList.contains("dark-theme") ? "dark" : "light";
      toggleThemeIcon();
      localStorage.setItem("theme", currentTheme);
    });
    currentIconList.forEach(icon => {
      if (icon.classList.contains("menu-item-notifications_button_small")) {
        customIcon = document.querySelector("span.menu-item-notifications_button_small");
        currentLink = document.querySelector("ul li.menu-item.menu-item-notifications_button_small a");
        changeIcons(currentLink, customIcon);
      } else if (icon.classList.contains("menu-item-cms")) {
        customIcon = document.querySelector("span.menu-item-cms");
        currentLink = document.querySelector("ul li.menu-item.menu-item-cms a");
        changeIcons(currentLink, customIcon);
      } else if (icon.classList.contains("menu-item-teach")) {
        customIcon = document.querySelector("span.menu-item-teach");
        currentLink = document.querySelector("ul li.menu-item.menu-item-teach a");
        changeIcons(currentLink, customIcon);
      } else if (icon.classList.contains("menu-item-user")) {
        customIcon = document.querySelector("span.menu-item-user");
        currentLink = document.querySelector("ul li.menu-item.menu-item-user a");
        changeIcons(currentLink, customIcon);
      } else if (icon.classList.contains("menu-item-tasks")) {
        customIcon = document.querySelector("span.menu-item-tasks");
        currentLink = document.querySelector("ul li.menu-item.menu-item-tasks a");
        changeIcons(currentLink, customIcon);
      } else if (icon.classList.contains("menu-item-notifications")) {
        customIcon = document.querySelector("span.menu-item-notifications");
        currentLink = document.querySelector("ul li.menu-item.menu-item-notifications a");
        changeIcons(currentLink, customIcon);
      } else if (icon.classList.contains("menu-item-sales")) {
        customIcon = document.querySelector("span.menu-item-sales");
        currentLink = document.querySelector("ul li.menu-item.menu-item-sales a");
        changeIcons(currentLink, customIcon);
      }
    })
  }
}
function createPannel() {
  let lessonHeader = document.querySelector(".lesson-header-block");
  if (lessonHeader) {
    let allText = document.querySelectorAll("p, a, h1, h2, h3, h4, h5, h6, span, li");
    let btnMenu = document.createElement("div");
    let div = document.createElement("div");
    let ul = document.createElement("ul");
    let scroll = document.createElement("li");
    let fPlus = document.createElement("li");
    let fMinus = document.createElement("li");
    btnMenu.classList.add("lesson__info__btn");
    div.classList.add("lesson__info__container");
    ul.classList.add("lesson__info__block");
    scroll.classList.add("lesson__info__scroll");
    fPlus.classList.add("lesson__info__fPlus");
    fMinus.classList.add("lesson__info__fMinus");
    scroll.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    });
    fPlus.addEventListener("click", () => {
      allText.forEach((p) => {
        let style = window.getComputedStyle(p, null).getPropertyValue("font-size");
        let fontSize = parseFloat(style);
        p.style.fontSize = (fontSize + 2) + "px";
      });
    });
    fMinus.addEventListener("click", () => {
      allText.forEach((p) => {
        let style = window.getComputedStyle(p, null).getPropertyValue("font-size");
        let fontSize = parseFloat(style);
        p.style.fontSize = (fontSize - 2) + "px";
      });
    });
    ul.append(fPlus);
    ul.append(fMinus);
    ul.append(scroll);
    div.append(btnMenu);
    div.append(ul);
    lessonHeader.append(div);
  }
}